import {Component, inject, input, OnChanges, OnInit, output, SimpleChanges} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {
  AbstractControl, FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import {InputNumberModule} from 'primeng/inputnumber';
import {CtSettings} from '../../../../models/ct-settings';
import {NgIf} from '@angular/common';
import {InputTextModule} from 'primeng/inputtext';
import {NgbPopover} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'esp-miscellaneous',
  standalone: true,
  imports: [
    TranslateModule,
    InputNumberModule,
    ReactiveFormsModule,
    NgIf,
    InputTextModule,
    NgbPopover
  ],
  templateUrl: './miscellaneous.component.html',
  styleUrl: './miscellaneous.component.css'
})
export class MiscellaneousComponent implements OnInit, OnChanges {
  ctSettings = input<CtSettings>();
  modelChanged = output<{
    backlog: number,
    projectedAsa: number,
    isValid: boolean
  }>();
  translateSrv = inject(TranslateService);
  fb = inject(FormBuilder);
  backlogForm: FormGroup;
  reverseSolveForm: FormGroup;
  projectedAsaInputType: string = 'text';

  constructor() {
    this.backlogForm = this.fb.group({
      backlogInput: new FormControl('0', this.minMaxValidator(0, 99999999, false, false))
    });

    this.reverseSolveForm = this.fb.group({
      projectedAsa: new FormControl(null, this.minMaxValidator(0, 9999, true, true))
    });
  }

  isWorkload():boolean {
    return this.ctSettings().workloadCt===true;
  }

  ngOnInit(){

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.ctSettings) {
      this.setBacklog();
      this.setReverseSolve();
    }
  }

  emitModelChanged() {
    let projectAsa = this.projectedAsa;
    if(projectAsa === ''){
      projectAsa = null;
    }else if(projectAsa !== '--'){
      projectAsa = Number(projectAsa);
    }
    this.modelChanged.emit({
      backlog: this.backlogInput,
      projectedAsa:projectAsa,
      isValid: !this.invalidBacklogInput && !this.invalidProjectedAsa
    });
  }

  private setBacklog() {
    if (this.isWorkload()) {
      this.backlogForm.controls["backlogInput"].setValue(this.ctSettings().startingBacklog, {emitEvent: false});
      this.backlogForm.controls["backlogInput"].enable();
    } else {
      this.backlogForm.controls["backlogInput"].setValue('', {emitEvent: false});
      this.backlogForm.controls["backlogInput"].disable();
    }
    // console.debug("setBacklog backlogFormControl:", this.backlogForm.controls["backlogInput"]);
  }

  public get backlogInput() {
    return this.backlogForm.get("backlogInput").value;
  }

  public get projectedAsa() {
    return this.reverseSolveForm.get("projectedAsa").value;
  }

  public get invalidBacklogInput() {
    return this.backlogForm.get("backlogInput").status === "INVALID";
  }

  public get invalidProjectedAsa() {
    return this.reverseSolveForm.get("projectedAsa").status === "INVALID" && this.reverseSolveForm.get("projectedAsa").dirty;
  }

  onBacklogInput(event: any) {
    this.backlogForm.controls["backlogInput"].setValue(event.value, {emitEvent: false});
    this.emitModelChanged();
  }

  onAsaThresholdInput(event: Event) {
    this.projectedAsaInputType = 'number';
    const inputValue = (event.target as HTMLInputElement).value;
    this.reverseSolveForm.get("projectedAsa").setValue(inputValue, {emitEvent: false});
    this.emitModelChanged();
  }

  private setReverseSolve() {
    // console.log("CT Settings",this.ctSettings());
    if(this.ctSettings().asaThresholdSecs==="--"){
      this.projectedAsaInputType = 'text';
    }else{
      this.projectedAsaInputType = 'number';
    }
    setTimeout(()=>{
      this.reverseSolveForm.controls["projectedAsa"].reset(this.ctSettings().asaThresholdSecs, {emitEvent: false});
    },0);
  }

  minMaxValidator(min, max, acceptBlank, acceptDashDash): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (acceptBlank && control.value === "") {
        return null; // Allow empty string
      } else if(control.value && this.isNumber(control.value)){
        const valNum = Number(control.value);
        if (!Number.isInteger(valNum) || valNum < min || valNum > max) {
          return { minMax: { value: control.value } };
        }
      }else{
        return null;
      }
    };
  }
  isNumber(value: string): boolean {
    if (typeof value === 'string') {
      return value.trim() !== "" && !isNaN(Number(value));
    }
    return !isNaN(value);  }
}
